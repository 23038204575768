<template>
  <div>
    <div class="columns mt-5">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.initials') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="personal.initials"
              class="input"
              :class="{ 'is-danger': required('initials') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.initials')}...`"
              tabindex="1"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.personal.first_name') }} <span class="is_blue">*</span></label>
          <div class="control">
            <input
              v-model="personal.first_name"
              class="input"
              :class="{ 'is-danger': required('first_name') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.first_name')}...`"
              tabindex="2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.personal.inserts') }}</label>
          <div class="control pr-2">
            <input
              v-model="personal.last_name_prefix"
              class="input"
              type="text"
              :placeholder="`${$t('message.fields.personal.inserts')}...`"
              tabindex="3"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.personal.last_name') }} <span class="is_blue">*</span></label>
          <div class="control">
            <input
              v-model="personal.last_name"
              class="input"
              :class="{ 'is-danger': required('last_name') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.last_name')}...`"
              tabindex="4"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.personal.gender') }} <span class="is_blue">*</span></label>
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="personal.sex"
                :class="{ 'is-red': required('sex') }"
                value="m"
                type="radio"
                name="gender"
                tabindex="5"
              />
              <i class="m-2" :class="{ is_red: required('sex') }">{{ $t('message.fields.personal.male') }}</i>
            </label>
            <label class="radio">
              <input
                v-model="personal.sex"
                :class="{ 'is-red': required('sex') }"
                value="f"
                type="radio"
                name="gender"
                tabindex="6"
              />
              <i class="m-2" :class="{ is_red: required('sex') }">{{ $t('message.fields.personal.female') }}</i>
            </label>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.marital_status') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio mr-5">
              <input
                v-model="personal.marital_status"
                :class="{ is_red: required('marital_status') }"
                value="gehuwd"
                type="radio"
                name="status"
                tabindex="7"
              />
              <i class="m-2" :class="{ is_red: required('marital_status') }"
                >{{ $t('message.fields.personal.maried') }}</i
              >
            </label>
            <label class="radio">
              <input
                v-model="personal.marital_status"
                :class="{ is_red: required('marital_status') }"
                value="ongehuwd"
                type="radio"
                name="status"
                tabindex="8"
              />
              <i class="m-2" :class="{ is_red: required('marital_status') }"
                >{{ $t('message.fields.personal.not_maried') }}</i
              >
            </label>
          </div>
        </div>
      </div>
    </div> -->
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.birth_date') }} <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <b-field>
              <b-datepicker
                v-model="personal.date_of_birth"
                :show-week-number="showWeekNumber"
                :locale="locale"
                :class="{ 'is-danger': required('date_of_birth') }"
                placeholder="dd/mm/jjjj"
                trap-focus
                tabindex="9"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.birth_place') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="personal.place_of_birth"
              class="input"
              :class="{ 'is-danger': required('place_of_birth') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.birth_place')}...`"
              tabindex="10"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.personal.address') }} <span class="is_blue">*</span></label>
          <div class="control pr-2">
            <input
              v-model="personal.street_name"
              class="input"
              :class="{ 'is-danger': required('street_name') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.address')}...`"
              tabindex="11"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <div class="columns">
            <div class="column is-two-thirds pb-0 pt-3">
              <div class="field">
                <label class="label"
                  >{{ $t('message.fields.personal.house_number') }}<span class="is_blue">*</span></label
                >
                <div class="control">
                  <input
                    v-model="personal.number"
                    class="input"
                    :class="{ 'is-danger': required('number') }"
                    type="text"
                    :placeholder="`${$t('message.fields.personal.house_number')}...`"
                    tabindex="12"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">{{ $t('message.fields.personal.addition') }}</label>
                <div class="control">
                  <input
                    v-model="personal.number_extention"
                    class="input"
                    type="text"
                    :placeholder="`${$t('message.fields.personal.addition')}...`"
                    tabindex="13"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.personal.zip_code') }} <span class="is_blue">*</span></label>
          <div class="control pr-2">
            <input
              v-model="personal.zip_code"
              class="input"
              :class="{ 'is-danger': required('zip_code') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.zip_code')}...`"
              tabindex="14"
            />
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">{{ $t('message.fields.personal.city') }} <span class="is_blue">*</span></label>
          <div class="control">
            <input
              v-model="personal.city"
              class="input"
              :class="{ 'is-danger': required('city') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.city')}...`"
              tabindex="15"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.email') }} <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <input
              v-model="personal.email"
              class="input"
              :class="{ 'is-danger': required('email') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.email')}...`"
              tabindex="16"
            />
          </div>
          <transition name="fade">
            <span
              class="is_red"
              v-if="personal.email && personal.email.length > 4"
              >{{ validation }}</span
            >
          </transition>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.phone') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="personal.mobile"
              class="input"
              :class="{ 'is-danger': required('mobile') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.phone')}...`"
              tabindex="17"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.nationality') }} <span class="is_blue">*</span></label
          >
          <div class="control pr-2">
            <select
                v-model="personal.nationality"
                class="input"
                :class="{ 'is-danger': required('nationality'), 'placeholder' : !personal.nationality }"
                :placeholder="`${$t('message.fields.personal.nationality')}...`"
                tabindex="18"
            >
                <option :value="null" disabled>Selecteer...</option>
                <option v-for="option in countryOptions" :key="option.id" :value="option.id">{{ option.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.medical') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="personal.medical_restriction"
              class="input"
              :class="{ 'is-danger': required('medical_restriction') }"
              type="text"
              :placeholder="`${$t('message.fields.personal.medical')}...`"
              tabindex="19"
            />
          </div>
        </div>
      </div> -->

      <div class="column">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.bsn') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <input
              v-model="personal.bsn"
              class="input"
              :class="{ 'is-danger': required('bsn') }"
              type="text"
              :placeholder="`${$t('message.fields.financial.bsn')}...`"
              tabindex="18"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.financial.loonheffingskorting') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio">
              <input
                v-model="personal.loonheffingskorting"
                :class="{ 'is-danger': required('loonheffingskorting') }"
                type="radio"
                :value="1"
              />
              {{ $t('message.options.yes') }}
            </label>
            <label class="radio">
              <input
                v-model="personal.loonheffingskorting"
                :class="{ 'is-danger': required('loonheffingskorting') }"
                type="radio"
                :value="0"
              />
              {{ $t('message.options.no') }}
            </label>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.license') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio">
              <input
                v-model="personal.drivers_licence"
                :class="{ 'is-danger': required('drivers_licence') }"
                type="radio"
                :value="1"
              />
              {{ $t('message.options.yes') }}
            </label>
            <label class="radio">
              <input
                v-model="personal.drivers_licence"
                :class="{ 'is-danger': required('drivers_licence') }"
                type="radio"
                :value="0"
              />
              {{ $t('message.options.no') }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="field">
          <label class="label"
            >{{ $t('message.fields.personal.car') }} <span class="is_blue">*</span></label
          >
          <div class="control">
            <label class="radio">
              <input
                v-model="personal.car_owner"
                :class="{ 'is-danger': required('car_owner') }"
                type="radio"
                :value="1"
              />
              {{ $t('message.options.yes') }}
            </label>
            <label class="radio">
              <input
                v-model="personal.car_owner"
                :class="{ 'is-danger': required('car_owner') }"
                type="radio"
                :value="0"
              />
              {{ $t('message.options.no') }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Constants from "../utils/constants"
export default {
  props: ["personal", "checked", "validation"],
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined, // Browser locale
      countryOptions: Constants.COUNTRIES,
    };
  },
  methods: {
    required: function() {
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mr_responsive {
  margin-right: 5%;
}
.placeholder {
  color: #cbcbcb;
}
</style>
