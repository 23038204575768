// Sometimes only the status field indicates failure, reject the Promise
// if this is the case
const parseResponse = function (response) {
  if (response.data.status === 'fail') {
    if (response.data.message) {
      let message = response.data.message
      
      if (response.data.errors) {
        message += '\n'

        for (const key in response.data.errors) {
          let keyErrors = response.data.errors[key]
          for (var i = 0; i < keyErrors.length; i++) {
            message += '\n- ' + keyErrors[i]
          }
    
        }
      }

      alert(message);
    }
    return Promise.reject(response)
  } else {
    return response
  }
}

const parseErrorResponse = function (error) {
  if (error.response.data.status === 'fail') {
    if (error.response.data.message) {
      let message = error.response.data.message
      
      if (error.response.data.errors) {
        message += '\n'

        for (const key in error.response.data.errors) {
          let keyErrors = error.response.data.errors[key]
          for (var i = 0; i < keyErrors.length; i++) {
            message += '\n- ' + keyErrors[i]
          }
    
        }
      }

      alert(message);
    }
  } else if (error.response.status === 500 ) {
    alert('Unknown error');
  }

  if (error.response && error.response.status === 401) {
    console.log("Error: " + error.response + " - " + error.response.status)
  }

  return error.response
}

const beforeRequest = function (config) {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
  }

  // TODO: if localStorage.getItem('token') is not eaqual to store.state.user.token, update token and also update axios default header (for images)
  // Does this ever occure??

  return config
}

export {parseResponse, parseErrorResponse, beforeRequest}
