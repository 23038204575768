<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="pdf-preview">
          <div
            v-if="
              !previewContract || !previewLoonheffing || !previewCodeOfConduct
            "
            class="loading-container"
            style="height: 100%; width: 100%; float: left; display:flex; justify-content: center; align-items: center;"
          >
            <div
              class="loader-cont"
              style="background-color: transparent !important;"
            >
              <img
                style="height: 120px !important; margin: auto; display: block;"
                src="../assets/images/loading-image.svg"
                alt=""
              />
            </div>
          </div>
          <div v-else class="content">
            <pdf
              v-if="hasContact"
              :key="10"
              :src="previewContract"
              :page="1"
              style="width: 100%; margin-bottom:10px;"
              @num-pages="pageCountContract = $event"
            ></pdf>
            <template v-if="previewContract && pageCountContract > 1">
              <pdf
                v-for="page in pageCountContract - 1"
                :key="10 + page"
                :src="previewContract"
                :page="page + 1"
                style="width: 100%; margin-bottom:10px;"
              ></pdf>
            </template>
            <pdf
              v-if="hasLoonheffing"
              :key="20"
              :src="previewLoonheffing"
              :page="1"
              style="width: 100%; margin-top:50px; margin-bottom:10px;"
              @num-pages="pageCountLoonheffing = $event"
            ></pdf>
            <template v-if="previewLoonheffing && pageCountLoonheffing > 1">
              <pdf
                v-for="page in pageCountLoonheffing - 1"
                :key="20 + page"
                :src="previewLoonheffing"
                :page="page + 1"
                style="width: 100%; margin-bottom:10px;"
              ></pdf>
            </template>
            <pdf
              v-if="hasCodeOfConduct"
              :key="30"
              :src="previewCodeOfConduct"
              :page="1"
              style="width: 100%; margin-top:50px; margin-bottom:10px;"
              @num-pages="pageCountCodeOfConduct = $event"
            ></pdf>
            <template v-if="previewCodeOfConduct && pageCountCodeOfConduct > 1">
              <pdf
                v-for="page in pageCountCodeOfConduct - 1"
                :key="30 + page"
                :src="previewCodeOfConduct"
                :page="page + 1"
                style="width: 100%; margin-bottom:10px;"
              ></pdf>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input
                v-model="contract.agreed"
                type="checkbox"
                :value="1"
              />
              {{ $t('message.fields.preview.agree') }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import pdf from "vue-pdf";
import Api from "../api/api";

let api = new Api();

export default {
  components: {
    pdf,
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
    language: {
      type: String,
      default: "nl",
    },
    checksum: {
      type: String,
    },
    setSigned: {
      type: Function
    },
    contract: {
      type: Object
    }
  },
  data() {
    return {
      today: moment().format("DD-MM-YYYY"),
      complete: false,
      loading: false,
      loadingContract: false,
      loadingLoonheffing: false,
      loadingCodeOfConduct: false,
      pageCountContract: 0,
      pageCountLoonheffing: 0,
      pageCountCodeOfConduct: 0,
      signed: false,
      previewContract: null,
      previewLoonheffing: null,
      previewCodeOfConduct: null,
      confirmRead: false,
      isSignatureFilled: false,
      hasContact: false,
      hasLoonheffing: false,
      hasCodeOfConduct: false
    };
  },

  computed: {
    saveButtonEnabled() {
      if (
        this.confirmRead &&
        this.isSignatureFilled &&
        this.previewContract &&
        this.previewLoonheffing &&
        this.previewCodeOfConduct
      ) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.makePdf();
  },

  methods: {
    makePdf: function() {
      this.loadingContract = true;
      this.loadingLoonheffing = true;
      this.loadingCodeOfConduct = true;

      api
        .get(`/contract/${this.hash}/${this.checksum}/preview/${this.language}`)
        .then((response) => {
          this.previewContract = "data:application/pdf;base64," + response.data;
          this.loadingContract = false;
          this.hasContact = !!response.data;
        })
        .catch(() => {
          this.loadingContract = false;
        });

      api
        .get(
          `/taxdeduction/${this.hash}/${this.checksum}/preview/${this.language}`
        )
        .then((response) => {
          this.previewLoonheffing =
            "data:application/pdf;base64," + response.data;
          this.loadingLoonheffing = false;
          this.hasLoonheffing = !!response.data;
        })
        .catch(() => {
          this.loadingLoonheffing = false;
        });

      api
        .get(
          `/codeofconduct/${this.hash}/${this.checksum}/preview/${this.language}`
        )
        .then((response) => {
          this.previewCodeOfConduct =
            "data:application/pdf;base64," + response.data;
          this.loadingCodeOfConduct = false;
          this.hasCodeOfConduct = !!response.data;
        })
        .catch(() => {
          this.loadingCodeOfConduct = false;
        });
    },

    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },

    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },

    saveSignature() {
      if (
        this.loading ||
        !this.previewContract ||
        !this.previewLoonheffing ||
        !this.previewCodeOfConduct
      ) {
        return;
      }

      this.loading = true

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        this.loading = false;
        return;
      }

      const fields = {
        employee_signature: data,
      };

      api
        .post(`/contract/${this.hash}/${this.checksum}/sign`, fields)
        .then(() => {
          this.loading = false;
          this.$refs.signaturePad.lockSignaturePad();
          this.complete = true;
          this.setSigned()
        })
        .catch((error) => {
          this.loading = false;
          console.log("Error");
          console.log(error);
        });
    },

    checkSignature() {
      const signatureData = this.$refs.signaturePad.toData();
      let points = 0;

      // Count total points
      signatureData.forEach((pointArray) => {
        points = points + pointArray.points.length;
      });

      if (points > 5) {
        this.isSignatureFilled = true;
      } else {
        this.isSignatureFilled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-preview {
  max-height: 100vh;
  overflow-y: scroll;
}
</style>
