export default {
  message: {
    work_at: 'Work for {appClient}',
    options: {
      yes: 'Yes',
      no: 'No'
    },
    greeting: {
      interested: 'Hello, potential team member!',
      career: 'If you are interested in a career at {appClient}, we would like to refer you to our vacancies page. Is there something for you? Then we would love to hear from you! ',
      farewell: 'Hope to see you soon, <br> The {appClient} team',
      to_applications: 'To vacancy page',
      applicant: 'Hello, <span class = "is_blue"> {name} </span>',
      first_steps: `These are the first steps in your career at {appClient}.
        We need some more information from you to finalize your application. Once everything is filled in, you've signed your contract and
        we've checked it, you will receive a link with login details for your personal app!`,
      personal: 'personal details',
      financial: 'bank and identification details'
    },
    steps: {
      personal: 'Personal information',
      contract: "Contract",
      pension: 'Pension',
      id: 'Bank and identification',
      sign: "Sign"
    },
    instructions: {
      fill: 'Enter your <span class = "is_blue"> {type} </span>',
      all_required: 'All data must be filled in to before you are able to send in the form!',

      // new
      personal: 'personal data',
      pension: 'pension data',
      financial: 'financial data'
    },
    fields: {
      personal: {
        initials: 'Initials',
        first_name: 'First name',
        inserts: 'Prefix',
        last_name: 'Last name',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        marital_status: 'Marital status',
        maried: 'Married',
        not_maried: 'Unmarried',
        birth_date: 'Date of birth',
        birth_place: 'Place of birth',
        address: 'Address',
        house_number: 'House number',
        addition: 'Addition',
        zip_code: 'Zip code',
        city: 'City',
        email: 'E-mail address',
        phone: 'Phone number',
        nationality: 'Nationality',
        medical: 'Do you have any medical limitations?',
        size: 'What is your shirt size?',
        license: "Do you have a valid driver's license without restrictions?",
        car: "Do you have your own car with passenger insurance?"
      },
      financial: {
        iban: 'IBAN',
        name: 'Name account holder',
        bank: 'Bank name',
        bsn: 'BSN (social security number)',
        type: 'Type of ID card',
        national: 'National passport',
        nl: 'NL ID card',
        eu: 'EU ID card',
        alien: 'Residence permit document',
        document_number: 'Passport / ID card number',
        expiration_date: 'Identification valid until',
        stipp: 'Do you have a pension with Stipp?',
        stipp_type: 'What kind of pension?',
        basic: 'Basic pension',
        plus: 'Plus pension',
        loonheffingskorting: 'Apply payroll tax credit',
        welfare: 'Do you receive social benefits?',
        welfare_type: 'Which social benefit(s)?',
        welfare_type_placeholder: 'Benefit(s)',
        other_job: 'Do you have another job?',
        ov: 'Do you have a public transport card?',
        none: 'None',
        week: 'Week',
        weekend: 'Weekend',

        // new
        card: 'Bank card',
        data_checker: 'After signing your contract you will receive an email from DataChecker with a link to upload your identification document. DataChecker will check the validity of your identification document. When validated your personal {appClient} account will be activated',
        front: 'Front of document',
        back: 'Back of document'
      },
      license: {
        owns: 'Do you have a drivers license?',
        name: 'Name + initials',
        type: 'Drivers license type',
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E',
        limitations: 'Do you have any restrictions on your drivers license?',
        limitations_description: 'What restrictions?',
        limitations_description_placeholder: 'Restrictions',
        created_at: 'Drivers license issue date',
        location: 'Drop off location',
        number: 'Drivers license number',
        expiration: 'Valid until',
        revoked: 'Have you been disqualified from driving?',
        revoked_reason: 'Where and why?',
        fines: 'Do you have any outstanding fines?',
        experience_questions: 'Questions about your <span class = "is_blue"> driving experience </span>',
        years_experience: 'Number of years of driving experience',
        serious_accident: 'Have you been involved in fatal or injured traffic accidents in the last 5 years?',
        criminal_record: 'Have you been convicted in that regard?',
        own_car: 'Do you have your own car?',
        insured: 'Is your car insured?',
        insured_passengers: 'Do you have passenger accident insurance?',
        damage: 'Have you caused damage in traffic, while driving, in the last 5 years?'
      },

      // new
      preview: {
        agree: 'I have read the content and agree'
      },
      pension: {
        explanation: 'Time flies when you’re having fun. So when working at {appClient}, times moves very fast! 😉 Your retirement might seem very far away right now, but it\'s important to get some things settled already.<br><br>Here at {appClient} we work with pension fund STiPP. Have you already built up some pension there? Great! That means we can reuse your pension history.<br><br>How do you find out if you have a pension history at STiPP? It\'s very simple: just login at <a href="http://mijnpensioenoverzicht.nl" target="_blank">www.mijnpensioenoverzicht.nl</a>. In case you\'ve already built up a pension at STiPP, hit “yes” and upload a screenshot of said page.',
        stipp: 'Do you accrue pension with STiPP?',
        type: 'STiPP type',
        basis: 'Basic',
        plus: 'Plus',
        overview: 'My pension overview',
        declare: 'I declare that I have no pension history with STiPP'
      }
    },
    complete: {
      success: '🥳 Yes! Almost done!',
      details: '{appClient} will now check your contract 👉 everything correct? 👉 then you will receive an email with login instructions for the {appClient} App. Welcome!',
      dataChecker: 'There\'s one last thing we need you to do: go through the Datachecker process. You will receive a sms from Datachecker with instructions.'
    },
    signed: {
      success: '🥳 Yes! Almost done!',
      details: '{appClient} will now check your contract 👉 everything correct? 👉 then you will receive an email with login instructions for the {appClient} App. Welcome!',
      dataChecker: 'There\'s one last thing we need you to do: go through the Datachecker process. You will receive a sms from Datachecker with instructions.'
    },
    controls: {
      next: 'Next',
      send: 'Send',
      back: 'Back'
    },
    signing: {
      signed: 'Documents have been signed.',
      signature: 'Signature',
      undo: 'Undo',
      clear: 'Clear',
      declare: 'I hereby declare I am signing my contract, the wage tax deduction form and the code of conduct.',
      sign: 'Sign'
    }
  }
}
