<template>
    <section class="input-section">
        <div v-if="!noTitle" class="input-title">
            <slot></slot>
        </div>
        <div class="input-container">
            <div v-if="!optional" class="dz-neutral"></div>
            <div v-if="isFilled == false" class="dz-explanation">Selecteer bestand om te uploaden...</div>
            <vue-dropzone
                id="drop1"
                ref="myDropzone"
                :include-styling="false"
                :options="dropOptions"
                :destroy-dropzone="false"
                @vdropzone-thumbnail="thumbnail"
                @vdropzone-success="fileUploadedSuccessfully"
                @vdropzone-removed-file="afterRemove"
                @vdropzone-error="displayError"
                @vdropzone-sending="checkForTimeout"
                @vdropzone-file-added="fileAdded"
            ></vue-dropzone>
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>
    </section>
</template>

<script>
    import vueDropzone from "vue2-dropzone"

    const getTemplate = () => `
	<div class="dz-preview dz-file-preview">
        <div class="dz-image">
            <div class="dz-background-thumb" data-dz-thumbnail-bg></div>
        </div>
        <div class="dz-details">
            <div class="dz-size"><span data-dz-size></span></div>
            <div class="dz-filename"><p class="max-text" data-dz-name></p></div>
        </div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
        <div class="dz-success-mark"></i></div>
        <div class="dz-error-mark"></i></div>
	</div>
`

    export default {
        components: {
            vueDropzone
        },
        props: [
            "value",
            "token",
            "preventDeleteFromServer",
            "isMedia",
            "autoProcess",
            "customUrl",
            "customParams",
            "onlyPDF",
            "userDocuement",
            "noTitle",
            "optional",
            "paramName"
        ],

        data() {
            return {
                dropOptions: {
                    acceptedFiles: this.getAcceptedFiles(),
                    previewTemplate: getTemplate(),
                    url: this.customUrl ? this.customUrl : "/api/media",
                    autoProcessQueue: this.autoProcess == "disabled" ? false : true,
                    params: this.customParams,
                    maxNumberOfFiles: 1,
                    maxFiles: 1,
                    maxFilesize: 5,
                    maxThumbnailFilesize: 2,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + this.token
                    },
                    paramName: this.paramName || "image",
                    createImageThumbnails: true,
                    thumbnailWidth: 80,
                    thumbnailHeight: 80,
                    addRemoveLinks: true,
                    resizeWidth: 2000
                },
                errorMessage: "",
                isFilled: false,
                file: ""
            }
        },

        watch: {
            isFilled: {
                handler() {
                    if (this.autoProcess == "disabled" && this.isFilled == true) {
                        this.value.valid = true
                    }
                },
                deep: true
            },
            customParams: {
                handler() {
                    this.$refs.myDropzone.setOption("params", this.customParams)
                },
                deep: true
            }
        },

        activated() {
            setTimeout(() => {
                this.displayThumbnail()
            }, 300)
        },

        created() {
            setTimeout(() => {
                this.displayThumbnail()
            }, 300)
        },

        mounted() {
        },

        methods: {
            processQue() {
                this.$refs.myDropzone.processQueue()
            },
            fileUploadedSuccessfully(file, response) {
                if (this.autoProcess == "disabled" && this.isFilled == true) {
                    this.$refs.myDropzone.removeFile(file)
                    this.$emit("uploaded", true)
                    return
                }
                if (this.userDocuement) {
                    this.$emit("uploaded", true)
                }
                this.value.value = response.data.hash_id
                if (!this.value.value) {
                    this.value.value = "pdf"
                }
                this.value.id = response.data.id
                this.value.valid = true
                this.isFilled = true
                this.errorMessage = ""
                this.file = file
            },

            displayError(file, message) {
                if (message == "Upload canceled.") {
                    message =
                        "Upload canceled. If the upload was canceled by the system reduce the file size or use faster internet."
                }

                this.errorMessage = message
                this.isFilled = false
                this.$refs.myDropzone.removeFile(file)
            },

            checkForTimeout(file, xhr) {
                xhr.ontimeout = () => {
                    this.$refs.myDropzone.removeFile(file)
                }
            },

            fileAdded() {
                this.errorMessage = ""
                this.isFilled = true
            },

            removeUpload() {
                this.isFilled = false
                this.$refs.myDropzone.removeFile(this.file)
            },

            afterRemove(file) {
                const tempValue = this.value.value

                if (this.preventDeleteFromServer) {
                    this.value.value = ""
                    this.value.valid = false
                    this.value.id = ""
                    this.isFilled = false
                } else {
                    if (this.value.value != "") {
                        let url
                        let value

                        if (file.type == "application/pdf" || this.value.value == "pdf") {
                            url = "deleteFile"
                            value = this.value.id
                        } else {
                            url = "deleteMedia"
                            value = this.value.value
                        }

                        this.$store
                            .dispatch(url, value)
                            .then(() => {
                                this.value.value = ""
                                this.value.valid = false
                                this.value.id = ""
                                this.isFilled = false
                                if (!this.errorMessage) {
                                    this.errorMessage = ""
                                }
                            })
                            .catch(() => {
                                //this.isFilled = true
                                this.value.value = tempValue
                                this.value.valid = true
                            })
                    }
                }
            },

            thumbnail: function(file, dataUrl) {
                var j, len, ref, thumbnailElement
                if (file.previewElement) {
                    file.previewElement.classList.remove("dz-file-preview")
                    ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]")
                    for (j = 0, len = ref.length; j < len; j++) {
                        thumbnailElement = ref[j]
                        thumbnailElement.alt = file.name
                        thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")'
                    }
                    return setTimeout(
                        (function() {
                            return function() {
                                return file.previewElement.classList.add("dz-image-preview")
                            }
                        })(this),
                        1
                    )
                }
            },

            displayThumbnail: function() {
                if (this.value.value != "" && this.value.value != "pdf") {
                    this.$store
                        .dispatch("getImage", this.value.value)
                        .then(response => {
                            var mockFile = { name: "Filename", size: 12345 }
                            this.$refs.myDropzone.manuallyAddFile(mockFile, response.data)
                        })
                        .catch(() => {})
                } else if (this.value.value == "pdf") {
                    var mockFile = { name: "PDF bestand geupload", size: 500000 }
                    this.$refs.myDropzone.manuallyAddFile(mockFile, "")
                }
            },

            getAcceptedFiles: function() {
                if (this.isMedia) {
                    return "image/jpeg,image/png,image/bmp,image/jpg"
                }

                return this.onlyPDF ? "application/pdf" : "image/jpeg,image/png,image/bmp,image/jpg,application/pdf"
            }
        }
    }
</script>

<style lang="scss" scoped>
    .input-container {
        height: 100px !important;
        position: relative;
        background: white;
    }
    .error-message {
        color: #e62a2a;
        font-size: 0.6rem;
        font-weight: 400;
        text-align: center;
        margin-top: -35px;
        max-height: 35px;
        overflow-y: scroll;
        padding: 0px 10px;
    }
</style>
<style lang="scss">
    .dz-error {
        .dz-error-mark {
            z-index: 10;
        }
    }
    .dz-background-thumb {
        background-image: url(/img/icons/file.svg);
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .dz-error {
        .dz-details,
        .dz-remove {
            display: none;
        }
    }
    #drop1 {
        height: 100px;
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    #drop1 .dz-preview {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .dz-error-message span {
        display: none;
    }

    .dz-explanation {
        height: 100%;
        width: 100%;
        color: #616161;
        top: 0;
        font-size: 0.8rem;
        text-align: center;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        pointer-events: none;
    }

    #drop1 .dz-preview .dz-details {
        position: absolute;
        left: 100px;
        margin: 10px;
    }

    .dz-filename,
    .dz-size {
        font-size: 0.8rem;
        padding-top: 5px;
    }
    .max-text {
        text-overflow: ellipsis; /* will make [...] at the end */
        width: 140px; /* change to your preferences */
        white-space: nowrap; /* paragraph to one line */
        overflow: hidden; /* older browsers */
    }

    .dz-remove {
        font-size: 0.8rem;
        position: absolute;
        left: 110px;
        top: 65px;
    }

    .dz-neutral {
        height: 100px;
        width: 0.3rem;
        position: absolute;
        background-color: #e0c263;
        right: 0;
        top: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    .dz-success-mark {
        width: 0.3rem;
        height: 100px;
        z-index: 10;
        position: absolute;
        right: -1px;
        top: -1px;
        background-color: #90be90;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    .dz-error-mark {
        width: 0.3rem;
        height: 100px;
        position: absolute;
        z-index: 0;
        right: -1px;
        top: -1px;
        background-color: red;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    #drop1 .dz-preview .dz-image {
        width: 80px;
        height: 80px;
        position: absolute;
        left: 10px;
        top: 10px;
    }

    #drop1 .dz-preview .dz-image > div {
        width: inherit;
        height: inherit;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    #drop1 .dz-preview .dz-image > img {
        width: 100%;
    }
    /* ... */
</style>
