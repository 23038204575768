export default {
  message: {
    work_at: 'Werken bij {appClient}',
    options: {
      yes: 'Ja',
      no: 'Nee'
    },
    greeting: {
      interested: 'Hallo, geïnteresseerde!',
      career: 'Als je geïnteresseerd bent in een carrière bij {appClient} dan verwijzen we je graag door naar onze pagina met vacatures. Zit er iets voor jou tussen? Dan horen we graag van je!',
      farewell: 'Misschien tot ziens,<br>Het {appClient} team',
      to_applications: 'Naar vacature pagina',
      applicant: 'Hallo, <span class="is_blue">{name}</span>',
      first_steps: `Hier de eerste stappen van jouw carrière bij {appClient}.
        We hebben nog even wat gegevens van je nodig om het
        alles helemaal compleet te maken. Zodra alles goed ingevuld en
        door ons bekeken is krijg je een link waarin je het contract kunt
        ondertekenen!`,
      personal: 'persoonlijke gegevens in',
      financial: 'financiële gegevens in'
    },
    steps: {
      personal: 'Persoonlijke gegevens',
      contract: "Contract",
      pension: 'Pensioen',
      id: 'Bank en identificatie',
      sign: "Tekenen"
    },
    instructions: {
      fill: 'Vul jouw <span class="is_blue">{type}</span> in',
      all_required: 'Om het formulier te versturen moeten alle gegevens ingevuld zijn!',
      
      personal: 'persoonlijke gegevens',
      pension: 'pensioen gegevens',
      financial: 'financiële gegevens'
    },
    fields: {
      personal: {
        initials: 'Voorletters',
        first_name: 'Voornaam',
        inserts: 'Tussenvoegsel(s)',
        last_name: 'Achternaam',
        gender: 'Geslacht',
        male: 'Man',
        female: 'Vrouw',
        marital_status: 'Burgerlijke staat',
        maried: 'Gehuwd',
        not_maried: 'Ongehuwd',
        birth_date: 'Geboortedatum',
        birth_place: 'Geboorteplaats',
        address: 'Adres',
        house_number: 'Huisnummer',
        addition: 'Toevoeging',
        zip_code: 'Postcode',
        city: 'Woonplaats',
        email: 'E-mail adres',
        phone: 'Telefoonnummer',
        nationality: 'Nationaliteit',
        medical: 'Heb je medische beperkingen?',
        size: 'Wat is je maat voor een overhemd?',
        license: 'Heb je een geldig rijbewijs zonder beperkingen?',
        car: 'Heb je een eigen auto met een inzittende verzekering?'
      },
      financial: {
        iban: 'IBAN',
        name: 'Naam rekeninghouder',
        bank: 'Bank naam',
        bsn: 'BSN (sofinummer)',
        type: 'Soort ID-bewijs',
        national: 'Nationaal paspoort',
        nl: 'NL ID-kaart',
        eu: 'EU ID-kaart',
        alien: 'Vreemdelingen document',
        document_number: 'Paspoort/ ID-kaart nummer',
        expiration_date: 'Legitimatie geldig tot en met',
        stipp: 'Bouw je al pensioen op met Stipp?',
        stipp_type: 'Wat voor pensioen?',
        basic: 'Basispensioen',
        plus: 'Pluspensioen',
        loonheffingskorting: 'Loonheffingskorting toepassen',
        welfare: 'Ontvang je een uitkering?',
        welfare_type: 'Welke uitkering?',
        welfare_type_placeholder: 'Uitkering',
        other_job: 'Heb je nog een andere baan?',
        ov: 'Heb je een OV-jaarkaart?',
        none: 'Geen',
        week: 'Week',
        weekend: 'Weekend',

        card: 'Bankpas',
        data_checker: 'Na het tekenen van je contract ontvang je een email van DataChecker met een link om je identificatie document te uploaden. DataChecker zal de validiteit van je identificatie document controleren. Wanneer deze goed bevonden is zal je persoonlijke {appClient} account geactiveerd worden.',
        front: 'Voorkant document',
        back: 'Achterkant document'
      },
      license: {
        owns: 'Heb je een rijbewijs?',
        name: 'Naam + voorletters',
        type: 'Type rijbewijs',
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E',
        limitations: 'Heb je een beperking op je rijbewijs?',
        limitations_description: 'Welke beperking?',
        limitations_description_placeholder: 'Beperking',
        created_at: 'Afgiftedatum rijbewijs',
        location: 'Afgifteplaats',
        number: 'Rijbewijsnummer',
        expiration: 'Geldig tot en met',
        revoked: 'Heb je ontzegging van je rijbevoegdheid?',
        revoked_reason: 'Waar en waarom?',
        fines: 'Heb je openstaande boetes?',
        experience_questions: 'Vragen over je <span class="is_blue">rij-ervaring</span>',
        years_experience: 'Aantal jaren rij-ervaring',
        serious_accident: 'Ben je de laatste 5 jaar betrokken geweest bij verkeersongevallen met dodelijke afloop of gewonden?',
        criminal_record: 'Ben je in dat verband veroordeeld?',
        own_car: 'Heb je een eigen auto?',
        insured: 'Is je auto verzekerd?',
        insured_passengers: 'Heb je een ongevallen inzittenden verzekering?',
        damage: 'Heb je de laatste 5 jaar schade gereden?'
      },

      preview: {
        agree: 'Ik heb de inhoud gelezen en ga akkoord'
      },
      pension: {
        explanation: 'Time flies when you’re having fun. Bij {appClient}, gaat de tijd dus razendsnel! 😉 Jouw pensioen lijkt natuurlijk heel ver weg, maar toch is het belangrijk om het een en ander alvast te regelen.<br><br>Bij {appClient} werken we met het pensioenfonds STiPP. Heb je daar al eerder pensioen opgebouwd? Dat is fijn!  We kunnen dan jouw pensioenverleden overnemen.<br><br>Hoe kom je erachter of je een pensioenverleden hebt bij STiPP? Heel simpel: log in op <a href="http://mijnpensioenoverzicht.nl" target="_blank">www.mijnpensioenoverzicht.nl</a>. Mocht je al pensioen hebben opgebouwd bij STiPP, klik op “ja” en upload hier dan een screenshot van.',
        stipp: 'Bouw je pensioen op bij STiPP?',
        type: 'STiPP type',
        basis: 'Basis',
        plus: 'Plus',
        overview: 'Mijnpensioenoverzicht',
        declare: 'Ik verklaar geen pensioenverleden bij STiPP te hebben'
      }
    },
    complete: {
      success: '🥳 Yes! Bijna klaar!',
      details: '{appClient} controleert jouw contract 👉 alles in orde? 👉 dan krijg jij per mail inloggegevens voor de {appClient} App. Welkom!',
      dataChecker: 'Het enige wat jij nog hoeft te doen: de Datachecker doorlopen. Je krijgt een sms van Datachecker met instructies.'
    },
    signed: {
      success: '🥳 Yes! Bijna klaar!',
      details: '{appClient} controleert jouw contract 👉 alles in orde? 👉 dan krijg jij per mail inloggegevens voor de {appClient} App. Welkom!',
      dataChecker: 'Het enige wat jij nog hoeft te doen: de Datachecker doorlopen. Je krijgt een sms van Datachecker met instructies.'
    },
    controls: {
      next: 'Volgende',
      send: 'Versturen',
      back: 'Terug'
    },
    signing: {
      signed: 'Documenten zijn ondertekend.',
      signature: 'Handtekening',
      undo: 'Ongedaan maken',
      clear: 'Wissen',
      declare: 'Ik teken voor zowel mijn contract als het opgaveformulier voor de loonheffingen en de gedragscode.',
      sign: 'Ondertekenen'
    }
  }
}